// utils/request.js

import axios from 'axios';
import CryptoJS from 'crypto-js';
import notification from '~/utils/notification.js'; // Adjust path as needed
import { hashing, base } from '~/utils/hashing.js'; // Adjust path as needed
import debug from '~/utils/logger.js'; // Adjust path as needed

class RequestClient {
  constructor(notification = false) {
    this.showNotification = notification;
    this.baseURL = base;
    this.notificationStack = [];

    // Create and configure a reusable Axios instance
    this.axiosInstance = axios.create({
      baseURL: this.baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    this.cache = new Map();
    this.cacheExpirationTime = 60 * 1000; // Cache expiration time in milliseconds (e.g., 1 minute)
  }

  token(key) {
    const secret = CryptoJS.lib.WordArray.random(128 / 8);
    const iv = CryptoJS.lib.WordArray.random(128 / 8);
    const hashingArray = CryptoJS.enc.Utf8.parse(hashing);
    const timestamp = Date.now();
    const keyWithTimestamp = { key, timestamp };
    const combinedKey = CryptoJS.lib.WordArray.create()
      .concat(secret)
      .concat(hashingArray);
    const token = CryptoJS.AES.encrypt(JSON.stringify(keyWithTimestamp), combinedKey, {
      iv: iv,
    }).toString();
    return { token, secret, iv, timestamp };
  }

  async connect(method, endpoint, data, key) {
    const isFullURL = /^(https?:\/\/)/.test(endpoint);
    const url = isFullURL ? endpoint : this.baseURL + endpoint;
    const { token, secret, iv } = this.token(key);

    const headers = {
      'client-token-key': key,
      'x-content-token': token,
      'x-content-sign': iv.toString(),
      'x-content-key': secret.toString(),
    };

    if (process.client && this.showNotification) {
      notification(`${method} Request`, `${endpoint} is Processing...`, 'normal', true);
    }

    try {
      if (method === 'GET' && data && data.cache && this.cache.has(endpoint)) {
        const cachedItem = this.cache.get(endpoint);
        const currentTime = Date.now();

        if (currentTime - cachedItem.timestamp <= this.cacheExpirationTime) {
          if (process.client && this.showNotification) {
            notification(`${method} Request`, `${endpoint} is Cached`, 'info', true);
          }
          return cachedItem.response;
        } else {
          this.cache.delete(endpoint);
        }
      }

      let response;
      if (method === 'DELETE') {
        response = await this.axiosInstance.request({
          method,
          url,
          headers,
        });
      } else {
        response = await this.axiosInstance.request({
          method,
          url,
          headers,
          data,
        });
      }

      if (method === 'GET' && data && data.cache) {
        const cachedItem = {
          response,
          timestamp: Date.now(),
        };
        this.cache.set(endpoint, cachedItem);
      }

      if (process.client && this.showNotification) {
        notification(`${method} Request`, `${endpoint} is Successful`, 'success', true);
      }
      return response;
    } catch (error) {
      debug.error('API request error:', error);
      if (process.client && this.showNotification) {
        notification(`${method} Request`, `${endpoint} is Failed`, 'error', true);
      }
      throw error;
    }
  }

  async GET(endpoint, key, cache = false) {
    return this.connect('GET', endpoint, { cache }, key);
  }

  async POST(endpoint, data, key, cache = false) {
    return this.connect('POST', endpoint, { cache, ...data }, key);
  }

  async PUT(endpoint, data, key) {
    return this.connect('PUT', endpoint, data, key);
  }

  async DELETE(endpoint, key) {
    debug.log("Delete", key);
    return this.connect('DELETE', endpoint, null, key);
  }
}

debug.log("Client Request :: V0.001 {axios}");
export default RequestClient;
